import React from "react";
import Layout from "../../components/general/Layout";
import DynamicLights from "../../components/examples/dynamic-lights";
import { Helmet } from "react-helmet";
import {
    EXPERIENCES,
    markExperienceAsVisited
} from "../../components/general/store";

const Engine_Room = () => {
    markExperienceAsVisited(EXPERIENCES.DYNAMIC_LIGHTS.key);
    return (
        <Layout>
            <Helmet>
                <script src="https://unpkg.com/aframe-randomizer-components@3.0.2/dist/aframe-randomizer-components.min.js"></script>
                <script src="https://unpkg.com/aframe-entity-generator-component@3.0.1/dist/aframe-entity-generator-component.min.js"></script>

                <script>
                    {`
                        AFRAME.registerComponent("random-material", {
                            init: function() {
                                this.el.setAttribute("material", {
                                    color: this.getRandomColor(),
                                    metalness: Math.random(),
                                    roughness: Math.random()
                                });
                            },

                            getRandomColor: function() {
                                var letters = "0123456789ABCDEF".split("");
                                var color = "#";
                                for (var i = 0; i < 6; i++) {
                                    color += letters[Math.floor(Math.random() * 16)];
                                }
                                return color;
                            }
                        });
                        `}
                </script>
                <script>
                    {`
                        AFRAME.registerComponent("random-torus-knot", {
                            init: function() {
                                this.el.setAttribute("geometry", {
                                    primitive: "torusKnot",
                                    radius: Math.random() * 10,
                                    radiusTubular: Math.random() * 0.75,
                                    p: Math.round(Math.random() * 10),
                                    q: Math.round(Math.random() * 10)
                                });
                            }
                        });
                    `}
                </script>
            </Helmet>
            <DynamicLights />
        </Layout>
    );
};

export default Engine_Room;
